import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import routes from 'routes.js'

const Auth = () => {
  const filterColor = 'yellow'

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <div className='wrapper wrapper-full-page'>
        <div className='full-page section-image' filter-color={filterColor}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from='/auth' to='/auth/login-page' />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Auth
