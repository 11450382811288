import React, { useState, useEffect } from 'react'

// Components
import DomainsTable from '../../components/DomainsTable/DomainsTable'
import Filters from '../../components/Filters/Filters'
import AddDomain from '../../components/AddDomain/AddDomain'

// Firebase
import firebase from '../../firebase/Firebase'

// Formik && YUP
import { Formik, Form } from 'formik'

// Classnames
import classnames from 'classnames'

// ReactStrap
import {
  Alert,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'

const Dashboard = () => {
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState(null)
  const [domains, setDomains] = useState(null)
  const [filterCategory, setFilterCategory] = useState(null)
  const [filterTemplateType, setFilterTemplateType] = useState(null)
  const [filterPriceRange, setFilterPriceRange] = useState(null)
  const [searchParameter, setSearchParameter] = useState(null)
  const [visible, setVisible] = useState(false)
  const categories = domains
    ? [...new Set(domains.map(doc => doc.category))]
    : []
  const db = firebase.firestore()

  let tableData = domains
  if (filterCategory) {
    if (filterCategory !== 'All Categories') {
      tableData = tableData.filter(doc => doc.category === filterCategory)
    }
  }
  if (filterTemplateType) {
    if (filterTemplateType !== 'All Types') {
      tableData = tableData.filter(
        doc => doc.templateType === filterTemplateType
      )
    }
  }
  if (filterPriceRange) {
    if (filterPriceRange[0] !== 'All Prices') {
      if (filterPriceRange[0] && filterPriceRange[1]) {
        tableData = tableData.filter(
          doc =>
            doc.price >= Number(filterPriceRange[0]) &&
            doc.price <= Number(filterPriceRange[1])
        )
      } else if (filterPriceRange[0]) {
        tableData = tableData.filter(
          doc => doc.price > Number(filterPriceRange[0])
        )
      }
    }
  }

  if (searchParameter) {
    tableData = tableData.filter(
      doc =>
        doc.category === searchParameter ||
        doc.url === searchParameter ||
        doc.category.includes(searchParameter) ||
        doc.url.includes(searchParameter)
    )
  }

  // Methods
  const refreshData = () => {
    db.collection('domains')
      .get()
      .then(data => {
        let domains = []
        data.forEach(doc => {
          domains.push({
            domainId: doc.id,
            url: doc.data().url,
            price: doc.data().price,
            category: doc.data().category,
            isBest: doc.data().isBest,
            templateType: doc.data().templateType,
            redirectURL: doc.data().redirectURL || '',
            createdAt: doc.data().createdAt
          })
        })
        setVisible(true)
        setDomains(domains)
      })
      .catch(e => {
        console.log(e)
        setError('Unable to find domains. Please refresh the page')
        setDomains(null)
      })
    setTimeout(() => {
      setVisible(false)
    }, 1500)
  }

  const toggle = () => {
    setVisible(!visible)
  }
  // Effects
  useEffect(() => {
    db.collection('domains')
      .get()
      .then(data => {
        let domains = []
        data.forEach(doc => {
          domains.push({
            domainId: doc.id,
            url: doc.data().url,
            price: doc.data().price,
            category: doc.data().category,
            isBest: doc.data().isBest,
            templateType: doc.data().templateType,
            redirectURL: doc.data().redirectURL || '',
            createdAt: doc.data().createdAt
          })
        })
        return setDomains(domains)
      })
      .catch(e => {
        console.log(e)
        setError('Unable to find domains. Please refresh the page')
        return setDomains(null)
      })
  }, [db])

  const handleSubmit = values => {
    setSearchParameter(values.searchParameter)
  }

  return (
    <div>
      <Alert
        color='info'
        className='alert-with-icon'
        isOpen={visible}
        toggle={toggle}
        style={{ width: '25%', marginLeft: 'auto', zIndex: '10' }}
      >
        <span data-notify='icon' className='now-ui-icons ui-1_bell-53'></span>
        <span data-notify='message'>Domains Updated</span>
      </Alert>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <div
          style={{
            width: window.innerWidth > 768 ? '15%' : '45',
            marginRight: '1rem',
            marginTop: window.innerWidth > 768 ? 0 : '-0.5rem'
          }}
        >
          <Formik
            initialValues={{
              searchParameter: searchParameter || ''
            }}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values)
              resetForm({})
            }}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <div style={{ marginTop: '1.5rem' }}>
                <Form>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': focus
                    })}
                    style={{ height: '1rem' }}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText style={{ backgroundColor: 'white' }}>
                        <i className='fa fa-search'></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='text'
                      placeholder='Search'
                      value={values.searchParameter}
                      onChange={e => {
                        setFieldValue(
                          'searchParameter',
                          e.target.value.toLowerCase()
                        )
                        if (e.target.value.trim() === '') {
                          setSearchParameter(null)
                        }
                      }}
                      onFocus={e => setFocus(true)}
                      onBlur={e => setFocus(false)}
                      style={{ backgroundColor: 'white' }}
                    />
                  </InputGroup>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <div>
        <Filters
          categories={categories}
          setFilterCategory={setFilterCategory}
          searchParameter={searchParameter}
          setSearchParameter={setSearchParameter}
          setFilterTemplateType={setFilterTemplateType}
          setFilterPriceRange={setFilterPriceRange}
        />
        <AddDomain refreshData={refreshData} />
        {error ? (
          <Container>
            <div className='text-center text-danger'>{error}</div>
          </Container>
        ) : null}
        <DomainsTable domains={tableData} refreshData={refreshData} />
      </div>
    </div>
  )
}

export default Dashboard
