import React from 'react'

// ReactStrap
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap'

// Formik && YUP
import { Formik, Form } from 'formik'

const Filters = props => {
  const {
    categories,
    setFilterCategory,
    setFilterTemplateType,
    setFilterPriceRange
  } = props
  const templateTypes = ['redirect with banner', 'redirect', 'form']
  const priceRanges = ['0-100', '100-250', '250-500', '500-1000', '1000+']

  // Methods
  const handleSubmit = values => {
    const priceRangeValues = values.priceRange.split('-')
    setFilterPriceRange([
      priceRangeValues[0].replace('+', ''),
      priceRangeValues[1]
    ])
    setFilterTemplateType(values.templateType)
    setFilterCategory(values.category)
  }
  return (
    <div>
      <Container className='mt-5'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: '-0.5rem'
          }}
        >
          <h5 className='text-left text-primary title'>FILTERS</h5>
        </div>

        <Card>
          <CardBody>
            <Container>
              <Formik
                initialValues={{
                  category: 'All Categories',
                  templateType: 'All Types',
                  priceRange: 'All Prices'
                }}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values)
                }}
                enableReinitialize
              >
                {({ values, setFieldValue }) => (
                  <div style={{ marginTop: '1.5rem' }}>
                    <Form>
                      <Container>
                        <Row>
                          <Col xs={12} md={3}>
                            <FormGroup style={{ width: '100%' }}>
                              <Label for='category'>Select a category</Label>
                              <Input
                                type='select'
                                name='select'
                                id='category'
                                value={values.category}
                                onChange={e => {
                                  setFieldValue('category', e.target.value)
                                }}
                              >
                                <option defaultValue>All Categories</option>
                                {categories.map((category, index) => (
                                  <option key={index}>{category}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={3}>
                            <FormGroup style={{ width: '100%' }}>
                              <Label for='templatType'>
                                Select a template type
                              </Label>
                              <Input
                                type='select'
                                name='select'
                                id='category'
                                value={values.templateType}
                                onChange={e => {
                                  setFieldValue('templateType', e.target.value)
                                }}
                              >
                                <option defaultValue>All Types</option>
                                {templateTypes.map((category, index) => (
                                  <option key={index}>{category}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={3}>
                            <FormGroup style={{ width: '100%' }}>
                              <Label for='priceRange'>
                                Select a price range($)
                              </Label>
                              <Input
                                type='select'
                                name='priceRange'
                                id='category'
                                value={values.priceRange}
                                onChange={e => {
                                  setFieldValue('priceRange', e.target.value)
                                }}
                              >
                                <option defaultValue>All Prices</option>
                                {priceRanges.map((price, index) => (
                                  <option key={index}>{price}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={3}>
                            <Button
                              color='primary'
                              type='submit'
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                width: '178px',
                                height: '38px',
                                marginLeft: '5rem',
                                marginTop: '1.5rem',
                                fontWeight: 'bold',
                                marginRight: '-0.8rem',
                                zIndex: '1',
                                padding: 0
                              }}
                            >
                              <i
                                className='fa fa-filter'
                                style={{
                                  marginTop: 'auto',
                                  marginLeft: '1.2rem',
                                  marginBottom: 'auto',
                                  padding: 0,
                                  fontSize: '0.9rem'
                                }}
                              ></i>
                              <p
                                className='text-center'
                                style={{
                                  marginTop: 'auto',
                                  marginLeft: 'auto',
                                  marginBottom: 'auto',
                                  padding: 0,
                                  width: '80%',
                                  fontSize: '0.8571rem'
                                }}
                              >
                                APPLY
                              </p>
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start'
                        }}
                      ></div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Container>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
export default Filters
