import React from 'react'
import ReactDOM from 'react-dom'
import './fonts/RobotoCondensed-Regular.ttf'
import './index.css'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'assets/scss/now-ui-dashboard.scss?v=1.4.0'
import 'assets/css/demo.css'

import AdminLayout from 'layouts/Admin.js'
import AuthLayout from 'layouts/Auth.js'

export const hist = createBrowserHistory()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route
        path='/admin'
        render={props => {
          return <AdminLayout history={hist} {...props} />
        }}
      />

      <Route
        path='/auth'
        render={props => {
          return <AuthLayout history={hist} {...props} />
        }}
      />
      <Redirect to='/admin/domains' />
    </Switch>
  </Router>,
  document.getElementById('root')
)
