import React, { useState } from 'react'

// ReactStrap
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input
} from 'reactstrap'

// Formik && YUP
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// Firebase
import firebase from '../../firebase/Firebase'

// DayJS
import dayjs from 'dayjs'

const DomainSchema = Yup.object().shape({
  url: Yup.string().required('Required *'),
  category: Yup.string().required('Required *'),
  templateType: Yup.string().required('Required *'),
  price: Yup.number().required('Required *'),
  isBest: Yup.boolean().required('Required *')
})

const AddDomain = props => {
  const [open, setOpen] = useState(null)
  const templateTypes = ['redirect with banner', 'redirect', 'form']
  const [error, setError] = useState(null)
  const { refreshData } = props
  const db = firebase.firestore()
  // Methods
  const toggle = () => setOpen(!open)
  const handleSubmit = (e, values) => {
    const newDomain = {
      url: values.url.toLowerCase(),
      category: values.category.toLowerCase(),
      price: Number(values.price),
      isBest: values.isBest,
      templateType: values.templateType.toLowerCase(),
      redirectURL: values.redirectURL,
      createdAt: Number(dayjs(new Date()).format('YYYYMMDDHHmmss'))
    }
    db.collection('domains')
      .add(newDomain)
      .then(doc => {
        return 'Success'
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <div>
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: '-4rem'
          }}
        >
          <Button
            color='primary'
            style={{
              marginLeft: 'auto',
              fontWeight: 'bold',
              marginRight: '1rem',
              zIndex: '1'
            }}
            onClick={toggle}
          >
            <i className='fa fa-plus mr-3'></i>
            CREATE DOMAIN
          </Button>
        </div>
        <div>
          <Modal isOpen={open} toggle={toggle}>
            <ModalHeader>
              <div
                style={{
                  diplay: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start'
                }}
              >
                <h6 className='title'>CREATE DOMAIN</h6>
                <Button
                  color='primary'
                  className='btn-neutral'
                  onClick={toggle}
                  style={{
                    zIndex: '1',
                    transform: 'scale(0.5)',
                    marginTop: '-1.25rem',
                    marginRight: '-1.5rem'
                  }}
                >
                  <i className='fa fa-times' aria-hidden='true'></i>
                </Button>
              </div>
            </ModalHeader>

            <Formik
              initialValues={{
                url: '',
                category: '',
                isBest: false,
                price: '',
                templateType: '',
                redirectURL: ''
              }}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values)
                resetForm({})
              }}
              validationSchema={DomainSchema}
              enableReinitialize
            >
              {({
                values,
                errors,
                setFieldValue,
                validateForm,
                setErrors,
                isValid
              }) => (
                <div>
                  <ModalBody>
                    <Form>
                      <FormGroup>
                        <Label for='url'>URL</Label>
                        <Input
                          type='text'
                          name='url'
                          id='URL'
                          placeholder='Domain URL'
                          onChange={e => setFieldValue('url', e.target.value)}
                        />
                      </FormGroup>
                      {errors.url ? (
                        <div
                          className='text-right text-danger'
                          style={{ fontSize: '0.725rem' }}
                        >
                          {errors.url}
                        </div>
                      ) : null}
                      <FormGroup>
                        <Label for='category'>Category</Label>
                        <Input
                          type='text'
                          name='category'
                          id='category'
                          placeholder='Category'
                          onChange={e =>
                            setFieldValue('category', e.target.value)
                          }
                        />
                      </FormGroup>
                      {errors.category ? (
                        <div
                          className='text-right text-danger'
                          style={{ fontSize: '0.725rem' }}
                        >
                          {errors.category}
                        </div>
                      ) : null}
                      <FormGroup>
                        <Label for='price'>Price($)</Label>
                        <Input
                          type='number'
                          name='price'
                          id='price'
                          placeholder='Price'
                          onChange={e => setFieldValue('price', e.target.value)}
                        />
                      </FormGroup>
                      {errors.price ? (
                        <div
                          className='text-right text-danger'
                          style={{ fontSize: '0.725rem' }}
                        >
                          {errors.price}
                        </div>
                      ) : null}
                      <FormGroup>
                        <Label for='templateType'>Select a template type</Label>
                        <Input
                          type='select'
                          name='select'
                          id='templatType'
                          onChange={e => {
                            setError(null)
                            setFieldValue('templateType', e.target.value)
                            if (e.target.value === 'none') {
                              setError('Please select a template type')
                            }
                          }}
                        >
                          <option defaultValue>none</option>
                          {templateTypes.map((templateType, index) => (
                            <option key={index}>{templateType}</option>
                          ))}
                        </Input>
                      </FormGroup>
                      {errors.templateType ? (
                        <div
                          className='text-right text-danger'
                          style={{ fontSize: '0.725rem' }}
                        >
                          {errors.templateType}
                        </div>
                      ) : null}
                      {error ? (
                        <div
                          className='text-right text-danger'
                          style={{ fontSize: '0.725rem' }}
                        >
                          {error}
                        </div>
                      ) : null}

                      {values.templateType === 'redirect' ||
                      values.templateType === 'redirect with banner' ? (
                        <FormGroup>
                          <Label for='redirectURL'>Redirect URL</Label>
                          <Input
                            type='text'
                            name='redirectURL'
                            id='redirectURL'
                            placeholder='Redirect URL'
                            onChange={e =>
                              setFieldValue('redirectURL', e.target.value)
                            }
                          />
                        </FormGroup>
                      ) : null}
                      <FormGroup check className='mt-2'>
                        <Label className='form-check-label'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            onChange={e =>
                              setFieldValue('isBest', !values.isBest)
                            }
                          />
                          Best Domain?
                          <span className='form-check-sign'>
                            <span className='check'></span>
                          </span>
                        </Label>
                      </FormGroup>
                      {errors.isBest ? (
                        <div
                          className='text-right text-danger'
                          style={{ fontSize: '0.725rem' }}
                        >
                          {errors.name}
                        </div>
                      ) : null}
                    </Form>
                  </ModalBody>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <div style={{ marginRight: '1.5rem' }}>
                      <Button
                        color='primary'
                        style={{ fontWeight: 'bold' }}
                        onClick={e => {
                          validateForm()
                            .then(res => {
                              if (
                                !res.url &&
                                !res.category &&
                                !res.isBest &&
                                !res.templateType &&
                                !res.price
                              ) {
                                handleSubmit(e, values)
                                refreshData()
                                toggle()
                              }
                            })
                            .catch(e => console.log(e))
                        }}
                        disabled={
                          (values.url === '' &&
                            values.category === '' &&
                            values.price === 0 &&
                            values.templateType === '') ||
                          !isValid ||
                          values.templateType === 'none'
                        }
                      >
                        CREATE
                      </Button>{' '}
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </Modal>
        </div>
      </Container>
    </div>
  )
}

export default AddDomain
