import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

// reactstrap components
import { Navbar, NavbarBrand, Container } from 'reactstrap'

class AdminNavbar extends React.Component {
  state = {
    isOpen: false,
    dropdownOpen: false,
    color: 'transparent',
    mobileOpen: false
  }
  sidebarToggle = React.createRef()
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent'
      })
    } else {
      this.setState({
        color: 'white'
      })
    }
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  dropdownToggle = e => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }
  openSidebar = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
    document.documentElement.classList.toggle('nav-open')
    this.sidebarToggle.current.classList.toggle('toggled')
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: 'white'
      })
    } else {
      this.setState({
        color: 'transparent'
      })
    }
  }
  componentDidMount () {
    window.addEventListener('resize', this.updateColor.bind(this))
  }
  componentDidUpdate (e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
      this.sidebarToggle.current.classList.toggle('toggled')
    }
  }
  render () {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          window.location.href.indexOf('full-screen-maps') !== -1
            ? 'white'
            : this.state.color
        }
        expand='lg'
        className={
          window.location.href.indexOf('full-screen-maps') !== -1
            ? 'navbar-absolute '
            : 'navbar-absolute ' +
              (this.state.color === 'transparent' ? 'navbar-transparent ' : '')
        }
        style={{ zIndex: 0 }}
      >
        <Container fluid>
          <div className='navbar-wrapper mb-2'>
            <div className='navbar-toggle'>
              <button
                type='button'
                ref={this.sidebarToggle}
                className='navbar-toggler'
                onClick={() => this.openSidebar()}
              >
                {!this.state.mobileOpen ? (
                  <i
                    className='fa fa-bars'
                    aria-hidden='true'
                    style={{ color: '#2CA8FF' }}
                  ></i>
                ) : (
                  <i
                    className='fa fa-times'
                    aria-hidden='true'
                    style={{ color: '#2CA8FF' }}
                  ></i>
                )}
              </button>
            </div>
            <NavbarBrand
              style={{ color: '#2CA8FF', marginLeft: '0.6rem' }}
              href='/'
            >
              {this.props.brandText}
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
    )
  }
}

AdminNavbar.defaultProps = {
  brandText: 'Default Brand Text'
}

AdminNavbar.propTypes = {
  // string for the page name
  brandText: PropTypes.string
}

export default AdminNavbar
