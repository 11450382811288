import React from 'react'

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from 'reactstrap'

// Firebase
import firebase from '../../firebase/Firebase'

import bgImage from 'assets/img/bg13.jpg'
import appLogo from 'assets/img/orangelogo.png'

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    document.body.classList.add('login-page')
  }
  componentWillUnmount () {
    document.body.classList.remove('login-page')
  }
  render () {
    return (
      <>
        <div className='content'>
          <div className='login-page'>
            <Container>
              <Col xs={12} md={8} lg={4} className='ml-auto mr-auto'>
                <Form>
                  <Card className='card-login card-plain'>
                    <CardHeader>
                      <div className='logo-container'>
                        <img
                          src={appLogo}
                          alt='app-logo'
                          style={{ transform: 'scale(5)' }}
                        />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Label for='email'></Label>
                      <InputGroup
                        className={
                          'no-border form-control-lg ' +
                          (this.state.emailFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fa fa-envelope' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='email'
                          type='email'
                          placeholder='Email'
                          onFocus={e => this.setState({ emailFocus: true })}
                          onBlur={e => this.setState({ emailFocus: false })}
                          onChange={e =>
                            this.setState({ email: e.target.value, error: '' })
                          }
                        />
                      </InputGroup>
                      <Label for='password'></Label>
                      <InputGroup
                        className={
                          'no-border form-control-lg ' +
                          (this.state.passwordFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fa fa-unlock-alt' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='password'
                          type='password'
                          placeholder='Password'
                          onFocus={e => this.setState({ passwordFocus: true })}
                          onBlur={e => this.setState({ passwordFocus: false })}
                          onChange={e => {
                            this.setState({
                              password: e.target.value,
                              error: ''
                            })
                          }}
                        />
                      </InputGroup>
                      {this.state.error ? (
                        <div
                          className='text-center text-danger mt-3'
                          style={{ fontSize: '0.825rem' }}
                        >
                          {this.state.error}
                        </div>
                      ) : null}
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        type='submit'
                        size='lg'
                        style={{ backgroundColor: '#F4831D' }}
                        className='mb-3 btn-round'
                        onClick={async e => {
                          e.preventDefault()
                          const { email, password } = this.state
                          if (password === '' || !password) {
                            this.setState({
                              error:
                                'Password required. Please fill in the password field!'
                            })
                          } else {
                            try {
                              await firebase
                                .auth()
                                .signInWithEmailAndPassword(email, password)
                              this.props.history.push('/admin/dashboard')
                            } catch (error) {
                              this.setState({
                                error:
                                  'Email or password not valid, please try again'
                              })
                            }
                          }
                        }}
                      >
                        Login
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className='full-page-background'
          style={{ backgroundImage: 'url(' + bgImage + ')' }}
        />
      </>
    )
  }
}

export default LoginPage
