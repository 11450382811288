import Dashboard from 'views/Dashboard/Dashboard.js'
import LoginPage from 'views/Pages/LoginPage'

let routes = [
  {
    path: '/domains',
    name: 'Domains',
    icon: 'now-ui-icons design_app',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    layout: '/auth',
    invisible: true
  }
]

export default routes
