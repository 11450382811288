import React, { useState } from 'react'

// React-table
import ReactTable from 'react-table'

// Components
import EditDomain from 'components/EditDomain/EditDomain'

// ReactStrap
import {
  Button,
  Container,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap'

// Firebase
import firebase from '../../firebase/Firebase'

// Dayjs
import dayjs from 'dayjs'

const DomainsTable = props => {
  const { domains } = props
  const [open, setOpen] = useState(null)
  const [domain, setDomain] = useState(null)
  const [alertRemoval, setAlertRemoval] = useState(false)
  const db = firebase.firestore()

  const toggle = () => {
    setAlertRemoval(!alertRemoval)
  }

  return (
    <div>
      {domains ? (
        <Container>
          <h5 className='text-left text-primary title mb-2'>DOMAINS</h5>
          <Card>
            <CardBody>
              <ReactTable
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      height: '40px',
                      overflow: 'hidden'
                    }
                  }
                }}
                data={domains
                  .sort((a, b) => b.createdAt - a.createdAt)
                  .sort((a, b) => {
                    if (a.url < b.url) {
                      return -1
                    }
                    if (a.url > b.url) {
                      return 1
                    }
                    return 0
                  })}
                loading={!domains}
                resizable={false}
                rowsText='domains'
                columns={[
                  {
                    Header: 'Created',
                    accessor: 'createdAt',
                    Cell: ({ row }) => {
                      return dayjs(row.createdAt.toString()).format(
                        'DD-MM-YYYY'
                      )
                    },
                    headerStyle: {
                      fontWeight: 'bold'
                    },
                    width: 100
                  },
                  {
                    Header: 'URL',
                    accessor: 'url',
                    sortable: false,
                    headerStyle: {
                      fontWeight: 'bold',
                      color: '#2CA8FF'
                    },
                    width: 250,
                    Cell: ({ row }) => {
                      const hrefTo = `https://${row._original.url}`
                      return (
                        <a
                          href={hrefTo}
                          style={{ color: '#2CA8FF', textDecoration: 'none' }}
                        >
                          {' '}
                          {row._original.url}
                        </a>
                      )
                    }
                  },
                  {
                    Header: 'Category',
                    accessor: 'category',
                    sortable: false,
                    headerStyle: {
                      fontWeight: 'bold'
                    },
                    width: 150
                  },
                  {
                    Header: 'Template Type',
                    accessor: 'templateType',
                    sortable: false,
                    headerStyle: {
                      fontWeight: 'bold'
                    },
                    width: 150
                  },
                  {
                    Header: 'Price($)',
                    accessor: 'price',
                    headerStyle: {
                      fontWeight: 'bold',
                      color: '#FA7A50'
                    },
                    width: 100,
                    Cell: ({ row }) => {
                      return row._original.price + ' $'
                    },
                    style: {
                      color: '#FA7A50'
                    }
                  },
                  {
                    Header: 'Best',
                    sortable: false,
                    accessor: 'isBest',
                    Cell: ({ row }) => {
                      return row.isBest ? (
                        <i className='fa fa-star' aria-hidden='true'></i>
                      ) : null
                    },
                    headerStyle: {
                      fontWeight: 'bold',
                      color: '#FA7A50'
                    },
                    style: {
                      color: '#FA7A50'
                    },
                    width: 50
                  },

                  {
                    Header: 'Actions',
                    sortable: false,
                    Cell: ({ row }) => {
                      return (
                        <div style={{ marginTop: '-0.7rem' }}>
                          <Button
                            id='edit-button'
                            className='btn-neutral ml-2 mr-2'
                            color='info'
                            size='sm'
                            onClick={e => {
                              setDomain(row._original)
                              setOpen(true)
                            }}
                            style={{
                              background: 'none'
                            }}
                          >
                            <i
                              className={'now-ui-icons ui-1_settings-gear-63'}
                            ></i>
                          </Button>

                          <Button
                            className='btn-neutral ml-2 mr-2'
                            color='danger'
                            size='sm'
                            onClick={e => {
                              setDomain(row._original)
                              setAlertRemoval(true)
                            }}
                            style={{
                              background: 'none'
                            }}
                          >
                            <i
                              className={'now-ui-icons ui-1_simple-remove'}
                            ></i>
                          </Button>
                        </div>
                      )
                    },
                    style: {
                      textAlign: 'right'
                    },
                    headerStyle: {
                      fontWeight: 'bold',
                      marginRight: '2rem'
                    }
                  }
                ]}
                defaultPageSize={10}
                className='-striped -highlight'
                showPaginationBottom
                style={{ zIndex: 0 }}
                noDataText='No domains found'
              />
              {domain ? (
                <EditDomain
                  open={open}
                  setOpen={setOpen}
                  domain={domain}
                  refreshData={props.refreshData}
                />
              ) : null}
            </CardBody>
          </Card>

          <Modal
            isOpen={alertRemoval}
            fade={false}
            toggle={toggle}
            modalClassName={window.innerWidth < 738 ? 'mt-5 ml-3' : 'mt-5 ml-5'}
            contentClassName='modal-login'
          >
            <ModalBody className='text-center'>
              <h4 className='m-0 text-primary'>
                Are you sure you want to remove this domain?
              </h4>
              <div className='font-icon-detail mt-4'>
                <i
                  className='fa fa-ban'
                  aria-hidden='true'
                  style={{ color: 'red', fontSize: '6rem' }}
                ></i>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color='primary'
                onClick={e => {
                  const { domainId } = domain
                  db.doc(`/domains/${domainId}`)
                    .get()
                    .then(doc => {
                      if (!doc.exists) {
                        console.log('Error', 'Document not found')
                      } else {
                        return db.doc(`/domains/${domainId}`).delete()
                      }
                    })
                    .then(() => {
                      props.refreshData()
                      return setAlertRemoval(false)
                    })
                    .catch(e => {
                      console.log(e)
                    })
                }}
              >
                Confirm
              </Button>
              <Button color='secondary' onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      ) : null}
    </div>
  )
}

export default DomainsTable
