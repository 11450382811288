import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import routes from 'routes.js'

// Firebase
import firebase from '../firebase/Firebase.js'

class Admin extends React.Component {
  state = {
    sidebarMini: false,
    backgroundColor: 'blue',
    loadingUser: true
  }
  notificationAlert = React.createRef()
  mainPanel = React.createRef()

  componentWillMount () {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ loadingUser: false })
      if (user) {
        return 'Success'
      } else {
        this.props.history.push('/auth/login')
      }
    })
  }

  minimizeSidebar = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false })
    } else {
      this.setState({ sidebarMini: true })
    }
    document.body.classList.toggle('sidebar-mini')
  }
  handleColorClick = color => {
    this.setState({ backgroundColor: color })
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  getActiveRoute = routes => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  render () {
    const { loadingUser } = this.state
    return loadingUser ? (
      <div className='wrapper-loader'>
        <div className='circle-loader'></div>
        <div className='circle-loader'></div>
        <div className='circle-loader'></div>
        <div className='shadow-loader'></div>
        <div className='shadow-loader'></div>
        <div className='shadow-loader'></div>
        <span>Loading</span>
      </div>
    ) : (
      <div className='wrapper'>
        <Sidebar
          {...this.props}
          routes={routes}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={this.state.backgroundColor}
        />
        <div className='main-panel' ref={this.mainPanel}>
          <AdminNavbar
            {...this.props}
            brandText={this.getActiveRoute(routes)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from='/admin' to='/admin/domains' />
          </Switch>
        </div>
      </div>
    )
  }
}

export default Admin
